@import '~antd/dist/antd.variable.min.css';
@import 'assets/styles/fonts/vazir/font-face.css';
  

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', 'Vazir', sans-serif;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.ant-input-password > input{
  direction: ltr !important;
}

.ant-input-password > .ant-input-suffix{
  margin: 0 0 0 4px;
}

.ant-input-affix-wrapper-rtl.ant-input-password {
  direction: ltr !important;
}

.ltr-input {
  direction: auto !important;
}

.ant-layout-content {
  padding: 0 !important;
}

.ant-layout {
  background: transparent !important;
}

.ant-menu-title-content, .ant-dropdown-menu-item {
  font-weight: bold !important;
}

